import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes, domainRoutes } from '@sso/shared/constants';
import { Logo } from '@sso/shared/components';

import { Container } from './Header.styles';
import { SignupLink } from './SignupLink';
import { SigninLink } from './SigninLink';

type HeaderProps = {
    navigation?: boolean;
    className?: string;
    logo?: boolean;
};

function Header({ navigation = true, logo = true, className }: HeaderProps) {
    return (
        <Container className={className}>
            {logo && <Logo dark />}
            {navigation && (
                <Switch>
                    <Route
                        component={undefined}
                        path={[
                            domainRoutes.passwordForgot,
                            routes.passwordForgot,
                            domainRoutes.passwordEmailVerification,
                            routes.passwordEmailVerification,
                            domainRoutes.passwordMfaVerification,
                            routes.passwordMfaVerification,
                            domainRoutes.passwordReset,
                            routes.passwordReset,

                            routes.signupPasswordCreation,
                            routes.signinPasswordVerification,
                            routes.signinPasswordCreation,
                            routes.signinPasswordCreationConfirmation,

                            routes.signinAws,
                            domainRoutes.signinMfaVerification,
                            routes.signinMfaVerification,

                            domainRoutes.signinDomainUserActivation,

                            domainRoutes.signinDomainTotpQRCode,
                            domainRoutes.signinDomainTotpCode,
                            domainRoutes.signinDomainTotpFinalize,

                            routes.signupDomainEnrollment,
                            routes.signupProfile,

                            routes.signupUserEmailVerification,

                            routes.signinSamlError,

                            routes.logout,
                        ]}
                        exact
                    />
                    <Route
                        component={SigninLink}
                        path={[
                            routes.signupEmailVerification,
                            routes.signupProductSelect,
                            routes.signup,
                        ]}
                        exact
                    />
                    <Route
                        component={SignupLink}
                        path={[
                            routes.signinDomainRecoveryConfirmation,
                            routes.signinDomainRecovery,
                            routes.signinDomainSelect,

                            routes.signinProductSelect,

                            domainRoutes.signinUsername,
                            domainRoutes.signinPassword,

                            domainRoutes.signin,
                            routes.signin,
                        ]}
                        exact
                    />
                </Switch>
            )}
        </Container>
    );
}

export default Header;
