import { useCallback } from 'react';

import { useAppDispatch, useAppStore } from '@sso/shared/store';
import { SignupMetadata, ApiError } from '@sso/shared/types';
import { MfaType, errorCodes } from '@sso/shared/constants';
import { ls } from '@sso/shared/utils';
import {
    useGetUserActivationTokenMutation,
    useGetUserForceResetTokenMutation,
    useLoginMutation,
    selectPendingSignupPathKey,
    restrainProductRedirect,
    selectProduct,
    LoginPayload,
} from '@sso/shared/redux';

import { useNavigate } from '../navigation';
import { useGtm } from '../gtm';

export default function useLogin() {
    const [getUserActivationToken] = useGetUserActivationTokenMutation();
    const [getUserForceResetToken] = useGetUserForceResetTokenMutation();
    const [login] = useLoginMutation();

    const dispatch = useAppDispatch();
    const store = useAppStore();

    const navigate = useNavigate();
    const gtm = useGtm();

    return useCallback(
        async (payload: Omit<LoginPayload, 'product'> & SignupMetadata) => {
            const { password, username, domain, registrationType, source } = payload;

            const product = selectProduct(store.getState())!;
            const awsToken = ls.getAmazonToken();

            try {
                dispatch(restrainProductRedirect(true));

                const res = await login({ ...payload, product, awsToken }).unwrap();
                const { accountRole, mfaConfiguration, trustedDeviceAllowed, mfaDetails } = res;

                gtm.sendAuthEvent({
                    event: gtm.events.login,
                    registrationType,
                    source,
                });

                if (mfaConfiguration && mfaConfiguration !== MfaType.None) {
                    navigate({
                        key: 'signinMfaVerification',
                        state: {
                            trustedDeviceAllowed,
                            mfaConfiguration,
                            accountRole,
                            username,
                            registrationType,
                            source,
                        },
                    });
                } else if (mfaDetails?.mfaCode && mfaDetails?.uri) {
                    navigate({
                        key: 'signinDomainTotpQRCode',
                        state: {
                            mfaCode: mfaDetails.mfaCode,
                            mfaUri: mfaDetails.uri,
                            username,
                        },
                    });
                } else {
                    const pendingSignup = selectPendingSignupPathKey(product)(store.getState());

                    if (pendingSignup) {
                        navigate({
                            key: pendingSignup,
                            state: {
                                allowed: true,
                                registrationType,
                                source,
                            },
                        });
                    }
                }
            } catch (e) {
                const error = e as ApiError;

                gtm.sendAuthEvent({
                    event: gtm.events.login,
                    registrationType,
                    source,
                });

                switch (error?.errorCode) {
                    case errorCodes.internal.ACTIVATION_CODE_NOT_ENTERED: {
                        navigate({
                            key: 'signupEmailVerification',
                            state: {
                                allowed: true,
                                email: username,
                                password,
                                registrationType,
                                source,
                            },
                        });

                        break;
                    }
                    case errorCodes.internal.FORCED_PASSWORD_RESET: {
                        const { token } = await getUserForceResetToken({
                            domain: domain!,
                            username,
                            password,
                        }).unwrap();

                        navigate({
                            key: 'passwordReset',
                            state: {
                                forced: true,
                                username,
                                token,
                            },
                        });

                        break;
                    }
                    case errorCodes.internal.OTP_PASSWORD_SETUP_REQUIRED: {
                        const { token } = await getUserActivationToken({
                            domain: domain!,
                            username,
                            password,
                        }).unwrap();

                        navigate({
                            key: 'signinDomainUserActivation',
                            state: {
                                confirmationToken: token,
                                username,
                            },
                        });

                        break;
                    }
                    default:
                        break;
                }

                throw e;
            } finally {
                dispatch(restrainProductRedirect(false));
            }
        },
        [login, getUserActivationToken, getUserForceResetToken, store, dispatch, gtm, navigate],
    );
}
